/* BM Colours 
  Teal rgba(92, 218, 215, 0.89)
  Frosted hsla(0, 0%, 100%, 0.7)

 */


.menuBar {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  /* height: 64px;
  width: calc(64px * 3); */
  /* background-color: hsla(0, 0%, 100%, 0.7); */


  display: flex;
  align-items: center;
  justify-content: space-evenly;
  pointer-events: none;

}

.menuItem {
  pointer-events: all;
  user-select: none;
  padding: 12px;
  background-color: hsla(0, 0%, 100%, 0.7);
  cursor: pointer;
}

.menuItem:hover {
  /* background-color: rgba(92, 218, 215, 0.89); */
}

.clickThrough {
  pointer-events: none;
}

.modalPopup {
  z-index: 100;
  background-color: hsla(0, 0%, 100%, 0.7);
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, 0);
  height: calc(100vh - 150px);
  width: 90vw;
  overflow: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

.modalPopup::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.widescreenLock {
  width: 100vw; 
  height: 56.25vw; /* 100/56.25 = 1.778 */
  max-height: 100vh;
  max-width: 177.78vh; /* 16/9 = 1.778 */
  margin: auto;
  position: relative;
  top:0;bottom:0; /* vertical center */
  left:0;right:0; /* horizontal center */
}