.galleryGrid {
  margin: 3rem;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.imageThumb {
  /* background-color: dodgerblue; */
  height: 15rem;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 479px) {
  .galleryGrid {
    margin: 3rem 1rem;
  }
}